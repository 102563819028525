import Modal from "react-bootstrap/Modal"
import { Images } from "../../../../../theme/Images"
// import "./style.scss"
import Button from "../../../../../components/Button"
import strings from "../../../../../translation"
import Input from "../../../../../components/Input"
import { useState } from "react"
import { addCoupon } from "../../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

function CouponModal(props) {
    const schema = yup.object().shape({
        coupon_id: yup
        .string()
        .required(`${strings.COUPON_ID_IS_REQUIRED}`)
        .matches(/^.*\S.*$/, strings.VALUE_IS_NOT_VALID),
        percentage: yup.string()
        .required(strings.DISCOUNT_PERCENTAGE_IS_REQUIRED)
        .matches(/^.*\S.*$/, strings.VALUE_IS_NOT_VALID),
        coupon_limit: yup.string().nullable().notRequired()
        .matches(/^.*\S.*$/, { message: strings.VALUE_IS_NOT_VALID, excludeEmptyString: true }),
        valid_month: yup.string().nullable().notRequired()
        .matches(/^.*\S.*$/, { message: strings.VALUE_IS_NOT_VALID, excludeEmptyString: true }),
      })

      const {
        control,
        handleSubmit,
        reset,
        formState: { errors }
      } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange"
      })

    const dispatch = useDispatch()
    const loading = useSelector(state => state.Subscriptions.couponLoading)

    const onAdd = (data) => {
        dispatch(addCoupon({
            payload: data,
            closeModal: HideModal
        }))
    }

 const HideModal = ()=>{
   reset(schema)
   props.onHide()
 }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="main-container"
      onHide={HideModal}
    >
      <Modal.Body className="modal-container">
        <div className="close-icon-container">
          <img
            src={Images.close}
            onClick={HideModal}
            className="modal-close-icon"
            alt="closeicon"
          ></img>
        </div>
        <div className="header-container">
          <h1 className="user-title">{strings.ADD_COUPON}</h1>
        </div>

        <div className="text-container">
        <Controller
              name="coupon_id"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  showError={errors.coupon_id?.message}
                  errorMessage={errors.coupon_id?.message}
                  placeholder={strings.COUPON_ID}
                />
              )}
            />
            <Controller
              name="percentage"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  showError={errors.percentage?.message}
                  errorMessage={errors.percentage?.message}
                  placeholder={strings.DISCOUNT_PERCENTAGE}
                />
              )}
            />
            <Controller
              name="coupon_limit"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  type="number"
                  showError={errors.coupon_limit?.message}
                  errorMessage={errors.coupon_limit?.message}
                  placeholder={strings.COUPON_LIMIT}
                />
              )}
            />
             <Controller
              name="valid_month"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  type="number"
                  showError={errors.valid_month?.message}
                  errorMessage={errors.valid_month?.message}
                  placeholder={strings.NUMBER_OF_MONTHS}
                />
              )}
            />
          <Button showSpinner={loading} className="full-btn board-btn" onClick={handleSubmit(onAdd)} title={strings.CONFIRM}/>
          <Button className="mt-4 full-btn board-btn yellow-outline-btn" onClick={HideModal} title={strings.CANCEL}/>
        </div>

        <div className="button-container">
          
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default CouponModal
