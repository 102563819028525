import { GET_COMPANY_LEADERBOARD, GET_COMPANY_LEADERBOARD_SUCCESS, GET_COMPANY_LEADERBOARD_FAILURE,REST_LEADERBOARD,REST_LEADERBOARD_SUCCESS,REST_LEADERBOARD_FAILURE } from "./types";

export const getCompanyLeaderBoard =(page, pageSize) =>({

    type: GET_COMPANY_LEADERBOARD,
    pageSize,
    page,
})
export const getCompanyLeaderBoardSuccess =(data)=>({
    type: GET_COMPANY_LEADERBOARD_SUCCESS,
    data
})
export const getCompanyLeaderBoardFailure = (error) =>({
    type: GET_COMPANY_LEADERBOARD_FAILURE,
    error
})


export const resetLeadeboard = (data,callBack)=>({
type: REST_LEADERBOARD,
data,
callBack
})

export const resetLeadeboardSuccess = (data)=> ({
type: REST_LEADERBOARD_SUCCESS,
data,
})

export const resetLeadeboardFailure = (error) => ({
    type:REST_LEADERBOARD_FAILURE,
    error
})
