import { Table } from "antd"
import "./style.scss"
import { Images } from "../../../../../theme/Images"
import { Popover } from "antd"
import { useState } from "react"
import EditUserModal from "../EditUserModal"
import DeleteUserModal from "../DeleteUserModal"
import EditComany from "../EditComany"
import strings from "../../../../../translation"

const NestedTable = props => {
  const [editModal, setEditModal] = useState(false)
  const [showdeleteModal, setshowdeleteModal] = useState(false)
  const [deleteUser, setDeleteUser] = useState()
  const [editCompany, setEditCompany] = useState()

  const PopoverContent = () => {
    return (
      <div className="moreContainer">
        <div className="more">
          <h4> {strings.MORE} </h4>
          <img src={Images.moreEdit} alt="moreedit" />
        </div>
        <div
          onClick={() => {
            if (deleteUser?.type === "user") {
              setEditModal(true)
            } else if (deleteUser?.type === "company") {
              setEditCompany(true)
            }
          }}
          className="pencil"
        >
          <img src={Images.editIconOrange} alt="edit" />
          <h4> {strings.EDIT} </h4>
        </div>
        <div onClick={() => setshowdeleteModal(true)} className="delete">
          <img src={Images.deleteIcon} alt="delete" />
          <h4> {strings.DELETE} </h4>
        </div>
      </div>
    )
  }

  const expandedRowRender = record => {
    const columns = [
      {
        title: `${strings.NAME}`,
        dataIndex: "employee_name",
        key: "employee_name"
      },
      {
        title: `${strings.ROLE}`,
        dataIndex: "employee_role",
        key: "employee_role",
        render: role => (
          <div className="pill">
            <p className="pill-text">
              {role === "business_admin" ? "Business Admin" : "Employee"}
            </p>
          </div>
        )
      },
      {
        title:`${strings.EMAIL}` ,
        dataIndex: "employee_email",
        key: "employee_email"
      },
      {
        title: `${strings.MOBILE_NUMBER}`,
        dataIndex: "employee_phone_no",
        key: "employee_phone_no"
      },
      {
        title: "",
        dataIndex: "info",
        key: "info",
        render: info => (
          <Popover
            content={PopoverContent(info)}
            trigger="click"
            placement="bottomRight"
          >
            <img
              onClick={() => setDeleteUser(info)}
              style={{ cursor: "pointer" }}
              src={Images.more}
              alt="more"
            />
          </Popover>
        )
      }
    ]

    const data = []
    for (let i = 0; i < record.employees.length; ++i) {
      data.push({
        key: i.toString(),
        employee_name: record?.employees[i]?.employee_name || "-",
        employee_role: record?.employees[i]?.employee_role || "-",
        employee_email: record?.employees[i]?.employee_email || "-",
        employee_phone_no: record?.employees[i]?.employee_phone_no || "-",
        info: {
          id: record?.employees[i]?.employee_id,
          name: record?.employees[i]?.employee_name || "-",
          email: record?.employees[i]?.employee_email,
          phone: record?.employees[i]?.employee_phone_no,
          
          type: "user"
        }
      })
    }
    return <Table columns={columns} dataSource={data} pagination={false} />
  }
  const columns = [
    {
      title: `${strings.ADMIN_NAME}`,
      dataIndex: "adminname",
      key: "adminname"
    },
    {
      title: `${strings.COMPANY_ACCOUNT}`,
      dataIndex: "name",
      key: "name",
      render: text => (
        <div className="pill">
          <p className="pill-text">{text}</p>
        </div>
      )
    },
    {
      title: `${strings.ADMIN_EMAIL}`,
      dataIndex: "adminemail",
      key: "adminemail"
    },
    {
      title: `${strings.ADMIN_MOBILE}`,
      dataIndex: "mobile",
      key: "mobile"
    },
    {
      title: "",
      dataIndex: "info",
      key: "info",
      render: info => (
        <Popover
          content={PopoverContent(info)}
          trigger="click"
          placement="bottomRight"
        >
          <img
            onClick={() => setDeleteUser(info)}
            style={{ cursor: "pointer" }}
            src={Images.more}
            alt="more"
          />
        </Popover>
      )
    }
  ]
  const data = []
  for (let i = 0; i < props.users.length; ++i) {
    data.push({
      key: i.toString(),
      name: props.users[i]?.company_detail[0]?.company_name || "-",
      adminname: props.users[i]?.name || "-",
      adminemail: props.users[i]?.email || "-",
      mobile: props.users[i]?.company_detail[0]?.phone_number || "-",
      employees: props.users[i]?.company_detail[0]?.employee_detail || [],
      info: {
        type: "company",
        id: props.users[i]?.id,
        name: props.users[i]?.company_detail[0]?.company_name || "-",
        email: props.users[i]?.email,
        phone: props.users[i]?.company_detail[0]?.phone_number,
        max_video_view_days: props.users[i]?.company_detail[0]?.max_video_view_days,
      }
    })
  }
  return (
    <>
      <Table
        columns={columns}
        expandable={{
          expandedRowRender
        }}
        dataSource={data}
        pagination={false}
        scroll={{ x: 768 }}
      />
      <EditUserModal
        selectedUser={deleteUser}
        onHide={() => setEditModal(false)}
        editModal={editModal}
      />
      <EditComany
        selectedUser={deleteUser}
        onHide={() => setEditCompany(false)}
        editCompany={editCompany}
      />
      <DeleteUserModal
        setPage={props.setPage}
        page={props.page}
        pageSize={props.pageSize}
        deleteUser={deleteUser}
        onHide={() => setshowdeleteModal(false)}
        showdeletemodal={showdeleteModal}
      />
    </>
  )
}
export default NestedTable
