import React , {useEffect,useState} from 'react'
import './style.scss'
import SidebarMenu from '../../../components/SidebarMenu'
import Breadcrumb from './Components/Breadcrumb'
import LeaderboardTable from './Components/LeaderboardTable'
import { Images } from '../../../theme/Images'
import strings from '../../../translation'
import Button from "../../../components/Button"
import { BASE_URL }  from "../../../config/app"
import axios from 'axios'
import ClearLeaderboardModal from './Components/ClearLeaderboardModal'

function CompanyLeaderboard(props) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showSideBar,setShowSideBar] = useState(false)
    const [showClearModal, setShowClearModal] = useState(false)

    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);


    return (
        <div style={{ display: "flex" }}>
            <SidebarMenu setShowSideBar={setShowSideBar} windowWidth={windowWidth} showSideBar={showSideBar}  activeTab= {strings.LEADERBOARD}/>

            <div className='leaderboard-layout'>
                <div className='top-search-bar'>
                    <h1 className='company-heading'>
                    {windowWidth<=992 &&!showSideBar && <img onClick={()=>setShowSideBar(true)} style={{width:40,height:40,marginRight:15,marginBottom:5}} src={Images.burgerIcon} alt="" />}
                        The Safety Team</h1>
                </div>

                <Breadcrumb />

                <div className='leaderboard-table-section'>
                    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                        <h1 className='table-heading'>{strings.POINT_RANKING}</h1>
                        <Button
                            title={strings.CLEAR_LEADERBOARD}
                            showIcon={false}
                            onClick={() => {
                                setShowClearModal(true)
                            }}
                        />
                    </div>
                    
                    <LeaderboardTable />
                </div>
            </div>
            <ClearLeaderboardModal showDeleteModal={showClearModal} onHide={() => setShowClearModal(false)} />
        </div>
    )
}

export default CompanyLeaderboard 