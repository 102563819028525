import Modal from 'react-bootstrap/Modal';
import { Images } from '../../../../../theme/Images';
import './style.scss'
import Button from '../../../../../components/Button';

import { useDispatch, useSelector } from 'react-redux';
import strings from '../../../../../translation'
import { BASE_URL }  from "../../../../../config/app"
import axios from 'axios'
import { getCompanyLeaderBoard } from "../../redux/actions"

function ClearLeaderboardModal(props) {
  const user = useSelector(state => state.login.loginInfo)
  const loading = useSelector(state => state.Settings.deleteAccountLoading)
  const dispatch = useDispatch()

 const onDelete  = async () => {
    const token = sessionStorage.token
    ? sessionStorage.token
    : await localStorage.getItem("token")
    const URL = `${BASE_URL}/api/v1/clear-leaderboard/`
    axios({
        method: 'post',
        url: URL,
        data: {},
        headers: {
            Accept: "application/json",
            "content-type": "application/json",
            'Authorization': 'Token '+token
        }
    }).then((response) => {
        dispatch(getCompanyLeaderBoard(1, 9))
        props.onHide()
    }
    ).catch((error) => {
        console.log(error)
    })
 }

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='main-container'
            show={props.showDeleteModal}
        >
            <Modal.Body className="modal-container">
                <div className='close-icon-container'>
                    <img
                        src={Images.close}
                        onClick={props.onHide}
                        className="modal-close-icon"
                        alt="closeicon">
                    </img>
                </div>
                <div className="header-container">
                    <h1 className='user-title'>{strings.CLEAR_LEADERBOARD}</h1>
                </div>

                <div className='text-container'>
                    <p>{strings.ARE_YOU_SURE_YOU_WANT_TO}<span className='bold-text'> {strings.CLEAR_LEADERBOARD}</span></p>
                </div>

                <div className='button-container'>
                    <Button showSpinner={loading} onClick={onDelete} className='delete-btn full-btn board-btn' title={`${strings.YES}`} />
                    <Button onClick={props.onHide} className='outline-btn full-btn board-btn' title={`${strings.NO}`} />
                </div>
            </Modal.Body>
        </Modal>
    );
}


export default ClearLeaderboardModal 