import { all, call, put, takeLatest } from "redux-saga/effects"
import { GET_COMPANY_LEADERBOARD } from "./types"
import { getCompanyLeaderBoardFailure, getCompanyLeaderBoardSuccess } from "./actions"
import UXR from '../../../../utils/XHR'
import { BASE_URL } from "../../../../config/app"



async function GetCompanyLeaderboardApi(page, pageSize) {
    const token = sessionStorage.token
    ? sessionStorage.token
    : await localStorage.getItem("token")
    const URL = `${BASE_URL}/api/v1/leaderboard/?page=${page}&page_size=${pageSize}`
    const options = {
        headers: {
            Accept: "application/json",
            "content-type": "application/json",
            Authorization: "Token "+token 
        },
        method: 'GET'
    }
    return UXR(URL, options)
}
function* GetCompanyLeaderboard({page, pageSize}) {
    try {
        const res = yield call(GetCompanyLeaderboardApi, page, pageSize)
        yield put(getCompanyLeaderBoardSuccess(res.data))
    } catch (e) {
        yield put(getCompanyLeaderBoardFailure(e.response))
    }
}





export default all([takeLatest(GET_COMPANY_LEADERBOARD, GetCompanyLeaderboard)])



